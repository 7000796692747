import { useMutation, useQuery } from 'react-query';
import { CreateUserDTO, UserDTO } from '~/dtos';
import { AxiosError } from 'axios';
import { UserService } from '~/services/User';
import { queryClient } from '~/constants';
import { message } from 'antd';
import { handleError } from '~/queries/common';

export const USERS_KEY = 'users';

export const useUsers = () =>
  useQuery<UserDTO[]>(USERS_KEY, () => UserService.getUsers());

export const useAddUser = (reset: () => void) =>
  useMutation<UserDTO, AxiosError, CreateUserDTO>(
    'addUser',
    (data) => UserService.createUser(data),
    {
      onSuccess: async () => {
        reset();
        await queryClient.refetchQueries(USERS_KEY);
        message.success('Dodano nowego użytkownika');
      },
      onError: handleError,
    },
  );
