import RequestService from './Request';

import {
  CreateUserDTO,
  UpdateUserDTO,
  UpdateUserPasswordDTO,
  UserDTO,
} from '~/dtos';

class _UserService {
  getMe(): Promise<UserDTO> {
    return RequestService.client
      .get<UserDTO>('/users/me')
      .then((response) => response.data);
  }

  getUsers(): Promise<UserDTO[]> {
    return RequestService.client
      .get<UserDTO[]>('/users')
      .then((response) => response.data);
  }

  createUser(data: CreateUserDTO): Promise<UserDTO> {
    return RequestService.client
      .post<UserDTO>('/users', data)
      .then((response) => response.data);
  }

  removeUser(id: string): Promise<void> {
    return RequestService.client
      .delete<void>(`/users/${id}`)
      .then(() => Promise.resolve());
  }

  updateUser(id: string, data: UpdateUserDTO): Promise<UserDTO> {
    return RequestService.client
      .patch<UserDTO>(`/users/${id}`, data)
      .then((response) => response.data);
  }

  updateUserPassword(id: string, data: UpdateUserPasswordDTO): Promise<void> {
    return RequestService.client
      .patch<void>(`/users/${id}/password`, data)
      .then(() => Promise.resolve());
  }
}

const UserService = new _UserService();

export { UserService, _UserService };
