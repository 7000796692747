import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { Layout, Menu } from 'antd';
import {
  TeamOutlined,
  UserOutlined,
  FileSearchOutlined,
} from '@ant-design/icons';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { Link as ReactLink } from 'react-router-dom';

import { ROUTES } from '~/constants';
import { useStoreState } from '~/global-state/hooks';
import { Role } from '~/dtos';

import Logo from '~/images/logo.jpg';
import LogoSquare from '~/images/just-logo.jpg';

const { Content, Footer, Sider } = Layout;
const { USERS, USER_PROFILE, PATIENTS } = ROUTES.dashboard;

interface Link {
  link: string;
  label: string;
  onlyAdmin?: boolean;
  public?: boolean;
  icon: ReactNode;
}

const LINKS: Link[] = [
  {
    link: ROUTES.LOGIN,
    icon: <UserOutlined />,
    label: 'Logowanie',
    public: true,
  },
  {
    link: PATIENTS,
    icon: <FileSearchOutlined />,
    label: 'Pacjenci',
    public: false,
  },
  {
    link: USERS,
    icon: <TeamOutlined />,
    label: 'Użytkownicy',
    public: false,
    onlyAdmin: true,
  },
  {
    link: USER_PROFILE,
    icon: <UserOutlined />,
    label: 'Twoje konto',
    public: false,
  },
];

export const RootContainer: React.FC = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const { data } = useStoreState((s) => s.user);
  const { push } = useHistory();
  const location = useLocation();
  const handleClick = useCallback(
    (link: string) => () => {
      push(link);
    },
    [push],
  );

  const links = useMemo(() => {
    if (!data) {
      return LINKS.filter((l) => l.public);
    }

    return LINKS.filter((l) =>
      l.onlyAdmin ? data?.role === Role.ADMIN : true,
    ).filter((l) => !l.public);
  }, [data]);

  const selectedMenuElement = useMemo(() => {
    return links.findIndex((l) => l.link === location.pathname);
  }, [links, location]);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {data && (
        <Sider
          theme='light'
          collapsible
          collapsed={collapsed}
          onCollapse={(val) => setCollapsed(val)}
        >
          <div
            style={{
              padding: 16,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <ReactLink to={ROUTES.dashboard.ROOT}>
              <img
                alt='logo'
                src={collapsed ? LogoSquare : Logo}
                style={{
                  maxWidth: '100%',
                  objectFit: 'contain',
                  maxHeight: collapsed ? 40 : 100,
                }}
              />
            </ReactLink>
          </div>
          <Menu mode='inline' selectedKeys={[`${selectedMenuElement}`]}>
            {links.map((l, idx) => (
              <Menu.Item key={idx} icon={l.icon} onClick={handleClick(l.link)}>
                {l.label}
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
      )}

      <Layout>
        <Content style={{ padding: '16px' }}>{props.children}</Content>
        <Footer
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <b>
            Gedeon Medica © {dayjs().format('YYYY')}. Wszelkie prawa
            zastrzeżone.
          </b>
        </Footer>
      </Layout>
    </Layout>
  );
};
