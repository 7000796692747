import { Button, Input, message, Modal } from 'antd';
import { AxiosError } from 'axios';
import React, { useCallback, useState } from 'react';
import { UseMutateFunction, useMutation } from 'react-query';
import { queryClient } from '~/constants';
import { UserDTO } from '~/dtos';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { UserService } from '~/services/User';

export interface Props {
  user: UserDTO;
}

const { confirm } = Modal;

function showDeleteConfirm(nickname: string, onOkFn: UseMutateFunction) {
  confirm({
    title: `Czy jesteś pewien że chcesz usunać ${nickname}?`,
    icon: <ExclamationCircleOutlined />,
    content:
      'Użytkownik zostanie usunięty bezpowrotnie i nie będzie mógł się już zalogować do systemu.',
    okText: 'Tak, usuń',
    okType: 'danger',
    cancelText: 'Nie chce usuwać tego użytkownika',
    onOk() {
      return onOkFn();
    },
  });
}

export const Actions: React.FC<Props> = (props) => {
  const { isLoading: isLoadingRemove, mutate: removeUserFn } = useMutation<
    void,
    AxiosError
  >('removeUser', () => UserService.removeUser(props.user.id), {
    onSuccess: async () => {
      await queryClient.refetchQueries('users');
      message.success('Użytkownik został usunięty');
    },
    onError: async (err) => {
      message.error(err.response?.data.message || 'Ops coś poszło nie tak.');
    },
  });

  const handleDelete = useCallback(() => {
    showDeleteConfirm(props.user.name, removeUserFn);
  }, [removeUserFn, props.user.name]);

  const [password, setPassword] = useState('');
  const handlePasswordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(e.target.value);
    },
    [],
  );

  const [isModalVisible, setModalVisible] = useState(false);
  const handleOpenChangePasswordModal = useCallback(() => {
    setModalVisible(true);
    setPassword('');
  }, []);

  const handleCancelPassword = useCallback(() => {
    setModalVisible(false);
    setPassword('');
  }, []);

  const { isLoading: isSettingPassword, mutate: updatePasswordFn } =
    useMutation<void, AxiosError, string>(
      'updatePassword',
      (password) =>
        UserService.updateUserPassword(props.user.id, {
          newPassword: password,
        }),
      {
        onSuccess: async () => {
          setPassword('');
          message.success('Hasło użytkownika została zaktualizowane');
          setModalVisible(false);
        },
        onError: async (err) => {
          message.error(
            err.response?.data.message || 'Ops coś poszło nie tak.',
          );
        },
      },
    );

  const handleOkPassword = useCallback(() => {
    updatePasswordFn(password);
  }, [updatePasswordFn, password]);

  return (
    <>
      <Button
        onClick={handleOpenChangePasswordModal}
        style={{ marginRight: 8 }}
      >
        Zmień hasło
      </Button>
      <Button danger loading={isLoadingRemove} onClick={handleDelete}>
        Usuń
      </Button>
      <Modal
        title='Zmień hasło'
        visible={isModalVisible}
        onOk={handleOkPassword}
        confirmLoading={isSettingPassword}
        onCancel={handleCancelPassword}
      >
        <Input
          placeholder='Nowe hasło'
          onChange={handlePasswordChange}
          value={password}
        />
      </Modal>
    </>
  );
};
